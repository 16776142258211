<template>
  <sub-page>

    <ws-data-table
        :headers="headers"
        :items="items"
        class="mt-5"
    >

      <template #item.registered="{item}">
        <h5>{{ PARSE_DATE(item.registered , false , true) }}</h5>
      </template>

    </ws-data-table>
  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newClients",
  data() {
    return {
      items : [],
      date : null,
      years : [],
      months : [],
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Plan') , value : 'plan' },
        { text : this.$t('Registered') , value : 'registered' },
        { text : this.$t('Sum') , value : 'payment_total' },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_DEACTIVATED_BUSINESSES',
    ]),

    async initPage() {
      let result = await this.GET_DEACTIVATED_BUSINESSES()
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.items
    }
  },
  watch : {
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>